<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center py-5"
      >
        <div class="w-100 align-items-center justify-content-center px-5">
          <div>
            <img
              :src="imgUrl1"
              class="logo-content"
              alt=""
            >
            <img
              :src="imgUrl2"
              class="logo-content"
              alt=""
            >
            <img
              :src="imgUrl3"
              class="logo-content"
              alt=""
            >
            <img
              :src="imgUrl4"
              class="logo-content"
              alt=""
            >
          </div>
          <h1>Evaluasi Anggaran Sistem Pemerintahan Berbasis Elektronik</h1>
          <p>Portal Evaluasi Sistem Pemerintahan Berbasis Elektronik (SPBE) merupakan aplikasi yang berbasis website
            yang dikelola oleh Kementerian Komunikasi dan Informatika Republik Indonesia dan Kementerian Pendayagunaan
            Aparatur Negara dan Reformasi Birokrasi yang bertujuan untuk memudahkan Kementerian dan Lembaga pemerintahan
            dalam mengajukan program kegiatan yang berkaitan dengan Sistem Pemerintahan Berbasis Elektronik (SPBE).</p>
          <p>Didukung Oleh: <br>
            <img
              :src="imgUrl7"
              class="logo-content2"
              alt=""
            >
          </p>
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            Login
          </b-card-title>

          <template v-if="alert">
            <b-alert
              :variant="alertDetail.variant"
              class="mt-2"
              show
            >
              <h4 class="alert-heading">
                {{ alertDetail.title }}
              </h4>
              <div class="alert-body">
                {{ alertDetail.text }}
              </div>
            </b-alert>
          </template>

          <!-- form -->
          <validation-observer
            ref="loginForm"
            #default="{ invalid }"
          >
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- nip -->
              <b-form-group
                label="Nomor Induk Pegawai"
                label-for="login-nip"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nomor Induk Pegawai"
                  vid="email"
                  rules="required"
                >
                  <b-form-input
                    id="login-nip"
                    v-model="userEmail"
                    :state="errors.length > 0 ? false : null"
                    name="login-nip"
                    placeholder="Nomor Induk Pegawai"
                    autofocus
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- forgot password -->
              <b-form-group>
                <div class="d-flex justify-content-between">
                  <label for="login-password">Password</label>
                </div>
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid' : null"
                  >
                    <b-form-input
                      id="login-password"
                      v-model="password"
                      :state="errors.length > 0 ? false : null"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      name="login-password"
                      placeholder="Password"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        class="cursor-pointer"
                        :icon="passwordToggleIcon"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- submit buttons -->
              <b-button
                type="submit"
                variant="primary"
                block
                :disabled="invalid"
              >
                Sign in
              </b-button>
            </b-form>
          </validation-observer>

          <b-card-text class="text-center mt-2">
            <b-button
              v-b-modal.modal-info
              variant="flat-primary"
            >
              Pengajuan Akun
            </b-button>
          </b-card-text>

        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>

    <b-modal
      id="modal-info"
      ref="modal-info"
      modal-class="modal-primary"
      hide-footer
      centered
      title="Pengajuan Akses Aplikasi"
    >
      <b-card-text>
        <p>
          Kementrian dapat mengajukan permohonan akun untuk akses ke aplikasi Evaluasi Anggaran SPBE melalui email ke :
        </p>
        <p class="text-center font-weight-bolder">
          clearance@kominfo.go.id<br>
          asdep2balaks@menpan.go.id
        </p>
        <p>Dengan menyertakan Nomor Induk Pegawai (NIP), Nama Lengkap, Email, Nomor Telepon dan Instansi.</p>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BCardText,
  BCardTitle,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
  VBModal,
} from 'bootstrap-vue'

import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

// import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    'b-tooltip': VBTooltip,
    'b-modal': VBModal,
  },
  components: {
    BRow,
    BCol,
    BAlert,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BCardText,
    BCardTitle,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      imgUrl1: require('@/assets/images/logo//logo-panrb-sm.png'),
      imgUrl2: require('@/assets/images/logo/kominfo-logo-full.svg'),
      imgUrl3: require('@/assets/images/logo/logo-kemenkeu-sm.png'),
      imgUrl4: require('@/assets/images/logo/logo-bappenas-sm.png'),
      imgUrl7: require('@/assets/images/logo/logo-bse-sm.png'),

      // validation rules
      required,
      alert: false,
      alertDetail: {
        variant: 'info',
        title: 'Info',
        text: '',
      },
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  methods: {
    login() {
      this.alert = false
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          this.$http.post('/login', {
            username: this.userEmail,
            password: this.password,
          })
            .catch(error => {
              this.alert = true
              this.alertDetail = {
                variant: 'danger',
                title: 'Error Found',
                text: error.response.data.message,
              }
            })
            .then(response => {
              if (response.data.status === 'success') {
                const userAllData = response.data.data
                localStorage.setItem('userToken', userAllData.token)

                const userData = userAllData.user_info
                const roleId = userData.group_id
                const userRole = {
                  role: userData.group_name,
                  ability: [
                    {
                      action: 'manage',
                      subject: 'all',
                    },
                  ],
                }

                this.$ability.update(userRole.ability)

                let year = new Date().getFullYear()

                if (roleId < 100 && userData.instansi_id === 43 && year === 2023) {
                  year += 1
                }

                localStorage.setItem('userData', JSON.stringify(userData))
                localStorage.setItem('roleId', JSON.stringify(roleId))
                localStorage.setItem('userRole', JSON.stringify(userRole))

                if (process.env.VUE_APP_MODE === 'dev') {
                  localStorage.setItem('tahunAnggaran', '2025')
                } else {
                  localStorage.setItem('tahunAnggaran', year.toString())
                }

                this.$router.replace(getHomeRouteForLoggedInUser(userAllData.role))
              } else {
                this.alert = true
                this.alertDetail = {
                  variant: 'danger',
                  title: 'Error Found',
                  text: response.data.message,
                }
              }
            })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
